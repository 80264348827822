<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A gas cylinder containing
        <latex-number :number="totalMass.toFixed(2)" unit="\text{g}" />
        of a gas mixture contains
        <number-value :value="massButane" unit="\text{g}" />
        of butane (<chemical-latex content="C4H10" />),
        <number-value :value="massPropane" unit="\text{g}" />
        of propane (<chemical-latex content="C3H8" />), and
        <number-value :value="massMethane" unit="\text{g}" />
        of methane (<chemical-latex content="CH4" />).
      </p>

      <p class="mb-2">
        a) Calculate the moles of butane, propane, and methane that are in the gas cylinder.
      </p>

      <calculation-input
        v-model="inputs.molButane"
        class="mb-2"
        prepend-text="$\text{Butane:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.molPropane"
        class="mb-2"
        prepend-text="$\text{Propane:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.molMethane"
        class="mb-5"
        prepend-text="$\text{Methane:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Calculate the partial pressure of butane in the storage tank if the total pressure in the
        tank is
        <number-value :value="Ptot" unit="\text{bar.}" />
      </p>

      <calculation-input
        v-model="inputs.Pbutane"
        class="mb-0"
        prepend-text="$\text{P}_\text{butane}:$"
        append-text="$\text{bar}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question451',
  components: {
    LatexNumber,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molButane: null,
        molPropane: null,
        molMethane: null,
        Pbutane: null,
      },
    };
  },
  computed: {
    massButane() {
      return this.taskState.getValueBySymbol('massButane').content;
    },
    massPropane() {
      return this.taskState.getValueBySymbol('massPropane').content;
    },
    massMethane() {
      return this.taskState.getValueBySymbol('massMethane').content;
    },
    totalMass() {
      return this.massButane.toFloat() + this.massPropane.toFloat() + this.massMethane.toFloat();
    },
    Ptot() {
      return this.taskState.getValueBySymbol('Ptot').content;
    },
  },
};
</script>
